import React, { FunctionComponent } from "react"
import { PageViews, PostNode } from "../../types/PostOverview"
import { Box, Heading, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import CustomButton from "../CustomButton"
import { colors, switchBlogPageHeaderMobile } from "../../theme/theme"
import ViewCountEye from "../ViewCountEye"
import { formattedDate } from "../../utils/formattedDate"

const StyledText = styled(Text)`
  line-height: 1.35rem;
`

const StyledBox = styled(Box)`
  height: auto;
  width: 17rem;
  margin-left: 1rem;
  align-content: "center";
  justify-content: "center";

  @media (max-width: ${switchBlogPageHeaderMobile}) {
    width: 100%;
    padding: 1rem 0.25rem 0 0.25rem;
    margin-left: 0;
  }
`

interface IBlogPageHeaderProps {
  post: PostNode
  views: PageViews
}

export const PreviewText: FunctionComponent<IBlogPageHeaderProps> = ({
  post,
  views,
}) => {
  const blogViews = views.edges.find(elem => {
    if (elem.node.path === post.node.frontmatter.path) {
      return elem
    }
  })
  post.node.views = blogViews?.node.count

  return (
    <StyledBox>
      <Heading as="h1" fontSize="1.5rem" paddingBottom="0.5rem">
        {post.node.frontmatter.title}{" "}
        {post.node.frontmatter.secondaryTitle && (
          <span style={{ color: colors.grey.dark }}>
            {" "}
            - {post.node.frontmatter.secondaryTitle}
          </span>
        )}
      </Heading>
      <StyledText textAlign="left">
        {post.node.frontmatter.description}
      </StyledText>
      <div style={{ margin: "0.5rem 0" }}>
        <StyledText style={{ color: "grey" }}>
          {post.node.frontmatter.time} min read
        </StyledText>
        <ViewCountEye views={post.node.views ? post.node.views : 0} />
        <StyledText style={{ color: "grey" }}>
          {formattedDate(post.node.frontmatter.date)}
        </StyledText>
      </div>
      <Link to={post.node.frontmatter.path} title={post.node.frontmatter.title}>
        <CustomButton text="read more" animated={true}>
          read more
        </CustomButton>
      </Link>
    </StyledBox>
  )
}

export default PreviewText
