import React, { FunctionComponent, useEffect, useState } from "react"
import { Flex, Box, Image, Text, Icon } from "@chakra-ui/react"
// @ts-ignore
import ProfilePicture from "../../assets/images/profile_picture.jpg"
import { colors, shadows } from "../../theme/theme"
import { twitterProfile } from "../../utils/routes"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import LoadingSkeleton from "../LoadingSkeleton"

interface IProfileBannerProps {
  text?: React.ReactNode
  hideSignature?: boolean
  styles?: React.CSSProperties
}

const imageHeight = "10rem"

export const ProfileBanner: FunctionComponent<IProfileBannerProps> = ({
  text = (
    <Text>
      Thanks for reading my post. I’d love to get feedback from you, so feel
      free to
      <a
        href={twitterProfile}
        style={{ color: colors.blue.blue2 }}
        target="_blank"
      >
        {" "}
        shoot me a tweet!
      </a>
    </Text>
  ),
  hideSignature,
  styles,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const [containerWidth, seContainerWidth] = useState<{
    width: "50%" | "100%"
    flexDirection: "column" | "row"
  }>({ width: "50%", flexDirection: "row" })

  const { width } = useWindowDimensions()

  useEffect(() => {
    if (width < 620) {
      seContainerWidth({ width: "100%", flexDirection: "column" })
    } else {
      seContainerWidth({ width: "50%", flexDirection: "row" })
    }
  }, [width])

  return (
    <Flex width="100%" height="auto" style={styles}>
      <Flex
        width="100%"
        flexDir={containerWidth.flexDirection}
        alignContent="center"
        alignItems="center"
      >
        <Image
          src={ProfilePicture}
          height={imageHeight}
          width={imageHeight}
          borderRadius="100%"
          objectPosition="right center"
          objectFit="cover"
          boxShadow={shadows.containerShadow}
          marginBottom="0"
          title="Hendrik Gruber Profile Picture"
          style={{ display: `${imageLoaded ? "" : "none"}` }}
          onLoad={() => {
            setImageLoaded(true)
          }}
        />
        {!imageLoaded && (
          <LoadingSkeleton
            height={imageHeight}
            width="13rem"
            radius="100%"
            styles={{ boxShadow: shadows.containerShadow, margin: "1rem" }}
          />
        )}
        <Flex width="100%" flexDir="column" margin="1rem">
          <Text fontStyle="italic" verticalAlign="middle">
            {text}
          </Text>
          {!hideSignature && <Text>- Hendrik</Text>}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProfileBanner
