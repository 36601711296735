import React, { FunctionComponent } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogPageHeader from "../components/BlogPageHeader/BlogPageHeader"
import { AllPosts } from "../types/PostOverview"
import { Divider, Flex, Text } from "@chakra-ui/react"
import MorePostsTiles from "../components/MorePostsTiles/MorePostsTiles"
import ProfileBanner from "../components/ProfileBanner/ProfileBanner"
import { twitterProfile } from "../utils/routes"
import { colors } from "../theme/theme"
import { myAge } from "../utils/globalVariables"
import NewsletterSignup from "../components/NewsletterSignup/NewsletterSignup"

export const BlogOverview: FunctionComponent = ({ children }) => {
  const data: AllPosts = useStaticQuery(graphql`
    query allPosts {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              path
              title
              secondaryTitle
              author
              authorTwitter
              date
              description
              tags
              time
              featuredImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
          }
        }
      }
      allTotalPageViews {
        edges {
          node {
            count
            id
            path
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Blog | HendrikGruber.com"
        meta={[
          {
            name: "Web Development Blog",
            content: "Blog posts about web development and cloud development",
          },
        ]}
      />
      <Flex flexDirection="column" width="100%">
        <BlogPageHeader
          post={data.allMarkdownRemark.edges[0]}
          views={data.allTotalPageViews}
        />
        <Divider margin="2rem 0" />
        <MorePostsTiles
          skipByTitle={data.allMarkdownRemark.edges[0].node.frontmatter.title}
        />
        <ProfileBanner
          hideSignature={true}
          text={
            <>
              <Text>
                Thanks for reading my blog! I'd love to hear your thoughts and
                get some feedback from you.{" "}
                <a
                  href={twitterProfile}
                  style={{ color: colors.blue.blue2 }}
                  target="_blank"
                >
                  Feel free to shoot me a tweet!
                </a>
              </Text>
              <Text>
                If you're in need of a website for your small business, club, or
                hobby, then{" "}
                <Link to="/hire" style={{ color: colors.blue.blue2 }}>
                  check out my hire me page.
                </Link>
              </Text>
            </>
          }
        />
        <NewsletterSignup />
      </Flex>
    </Layout>
  )
}

export default BlogOverview
