import React, { FunctionComponent } from "react"
import { PageViews, PostNode } from "../../types/PostOverview"
import { Box } from "@chakra-ui/react"
import PreviewImage from "./PreviewImage"
import { PreviewText } from "./PreviewText"
import { switchMobile } from "../../theme/theme"
import styled from "@emotion/styled"

const StyledBox = styled(Box)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  display: flex;
  @media (max-width: ${switchMobile}) {
    flex-direction: column;
    display: flex;
  }
`
interface IBlogPageHeaderProps {
  post: PostNode
  views: PageViews
}

export const BlogPageHeader: FunctionComponent<IBlogPageHeaderProps> = ({
  post,
  views,
}) => {
  return (
    <>
      <StyledBox>
        <PreviewImage post={post} />
        <PreviewText post={post} views={views} />
      </StyledBox>
    </>
  )
}

export default BlogPageHeader
