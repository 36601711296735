import React, { FunctionComponent, useState } from "react"
import { PostNode } from "../../types/PostOverview"
import { Box, Image } from "@chakra-ui/react"
import styled from "@emotion/styled"
import {
  cardRadius,
  hoverScaleEffect,
  shadows,
  switchBlogPageHeaderMobile,
} from "../../theme/theme"
import { Link } from "gatsby"
import LoadingSkeleton from "../LoadingSkeleton"

const StyledLink = styled(Link)`
  @media (max-width: ${switchBlogPageHeaderMobile}) {
    width: 100%;
    margin-right: 0;
  }
`

const StyledImage = styled(Image)`
  object-fit: cover;
  width: 23rem;

  :hover {
    box-shadow: ${shadows.containerShadowHighlight};
  }

  @media (max-width: ${switchBlogPageHeaderMobile}) {
    width: 100%;
  }
`

const StyledBox = styled(Box)`
  height: auto;
  width: 23rem;
  margin-right: 1rem;

  ${hoverScaleEffect}

  @media (max-width: ${switchBlogPageHeaderMobile}) {
    width: 100%;
    margin-right: 0;
  }
`

interface IBlogPageHeaderProps {
  post: PostNode
}

export const PreviewImage: FunctionComponent<IBlogPageHeaderProps> = ({
  post,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  return (
    <StyledLink
      to={post.node.frontmatter.path}
      title={post.node.frontmatter.title}
    >
      <StyledImage
        src={post.node.frontmatter.featuredImage?.childImageSharp.fluid.src}
        alt={post.node.frontmatter.title + " Image"}
        width="100%"
        borderRadius={cardRadius}
        border="2px solid white"
        boxShadow={shadows.containerShadow}
        style={{ display: `${imageLoaded ? "" : "none"}` }}
        onLoad={() => {
          setImageLoaded(true)
        }}
      />
      <StyledBox>
        {!imageLoaded && (
          <LoadingSkeleton
            height="20rem"
            width="100%"
            radius="cardRadius"
            styles={{ boxShadow: shadows.containerShadow }}
          />
        )}
      </StyledBox>
    </StyledLink>
  )
}

export default PreviewImage
